.card {
  &.competition {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .card-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }  
  } 
}