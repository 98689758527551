.button {

  &.is-square {
    border-radius: 0;
  }

  &.cta {
    padding-top: 32px;
    padding-right: 50px;
    padding-bottom: 32px;
    padding-left: 50px;
    border-width: 2px;
    outline: none;
  }

  &.cta:hover {
    border-width: 2px;
  }

  &.is-transparent {
    background-color: transparent;
    color: #fff;
  }

  &.is-transparent:hover {
    background-color: #fff;
    color: transparent;
    border-color: #fff;
  }

}

* {
  transition: background-color 0.5s ease;
}

body {
  line-height: 1;
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative;
}

body,
p,
h1,
h2,
h3,
h4 {
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: auto;
}

h1 {
	font-weight: 300;
	font-size: 3.8em;
	margin: 0;
	-webkit-font-smoothing: auto;
}

p, address, ul {
	font-size: 1.38em;
	margin-bottom: 20px;
	font-weight: 300;
	line-height: 1.6em;
	font-family: 'Lato', sans-serif;
	-webkit-font-smoothing: auto;
}

.subtext {
  font-size: 1.1em;
  line-height: 1.6em;
}

@media #{$desktop} {
  .subtext {
    font-size: 1.38em;
    line-height: 1.6em;
  }
}
