.card {
  &.publication {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .card-image {

    }
    .card-content {
      flex: 1;
    }
    .card-footer {
    }
  }
}

.image {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  height: 300px;

  display: flex;
  justify-content: space-around;

  &.publication-cover {
    text-align: center;
    img {
      padding: 10px;
      box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);      height: 100%;
      width: auto;
      left: unset;
      right: unset;
    }
  }
}