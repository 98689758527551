.hero-body {
  padding: 0;
  flex-direction: column;
}

.hero-content {
  padding-top: 110px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;

  &.is-small {
    padding-top: 50px;
  }
}

.hero-image {
  position: relative;
  color: #ffffff;
	text-align: center;
  width: 100%;
	min-height: 700px;
  padding-top: 90px;
  padding-bottom: 20px;
	background-color: #333;
	background-repeat: no-repeat;
	background-size: cover;
  background-position: center center;

  &.is-small {
    min-height: 300px;
    height: 20vh;
  }

  &.is-small:before {
    background: linear-gradient(to bottom,
      rgba(0,0,0,0.85) 0%,
      rgba(255,255,255, 0.5) 60%,
      rgba(255,255,255, 1) 100%);
  }

  &:before {
		content: "";
		background: linear-gradient(to bottom,rgba(0,0,0,0.85) 0,rgba(0, 0, 0, 0) 100%);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 300px;
  }
}

.inner-hero {
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding: 0 20px;
  position: relative;
}

@media #{$desktop} {
  .hero-image {
    height: 100vh;
  }

  .inner-hero {  

    align-items: center;

    &.inner-hero-right {
      margin-left: 50%;
    }
  
    &.inner-hero-left {
      margin-right: 50%;
    }
  }
}

.hero-text-container {
  width: 600px;
  text-align: left;
  background-color: rgba(0,0,0,0.35);
  padding: 10px;
  margin: 0 auto;

  h1, h2, p, .button {
    text-shadow: 1px 1px 1px rgba(0,0,0,.8);
  }
}
