// Navigation bar
nav {
  color: #fff;

  .navbar-burger {
    color: #fff;
  }

  &.header-background {
		background-color: rgba(255,255,255,.95) !important;
		color: #333;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
		transition: background-color 0.2s ease;
		text-shadow: none;

		.dark-logo {
      color: #333;
			display: inline;
		}

		.light-logo {
			display: none;
    }
    
    .navbar-burger {
      color: #333;
    }

    .navbar-item {
      color: #333;

      &.is-active {
        color: #333 !important;
        border-bottom: solid 5px orange;
      }
    }
  }
}

.logo {
  font-size: 1.8em;
  font-family: 'Great Vibes', cursive;
  align-self: center;
}

@media #{$desktop} {
  .logo {
    font-size: 3em;
  }
}

.dark-logo {
	display: none;
}

.light-logo {
  color: #fff;
}

.navbar-brand {
  margin-left: 20px;
  margin-right: 20px;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.navbar {
  padding-top: 20px;
  padding-bottom: 10px;

  &.is-active {
    color: #333 !important;
    background-color: rgba(255,255,255,.95) !important;
		transition: background-color 0.5s ease;

    .light-logo {
      color: #333;
    }
  }
}

.navbar-menu {

  box-shadow: none;

  &.is-active {

    background-color: transparent;

    .navbar-item {
      color: #333 !important;
      border-bottom: none;
    }
  }
}

a.navbar-item {
  font-size: .8em;
  color: #fff;
  border-bottom: solid 5px transparent;
  text-transform: uppercase;
  margin-left: 10px;

  &:hover {
    color: #fff;
    border-bottom: solid 5px lightgray;
  }

  &.is-active {
    color: #fff !important;
    border-bottom: solid 5px orange;
  }
}

@media #{$desktop} {
	header {
		.company-name {
			font-size: 3em;
		}
  }
}

