.info {
  text-align: center;

  h1 {
    margin-bottom: 10px;
  }

  h1, p {
    text-align: left;
  }

  img {
    max-height: 650px;
  }
}

.columns.is-alternate:nth-child(even) {
  flex-direction: row-reverse;
}


.learn-more {
  display: none;
}

@media #{$desktop} {
	.learn-more {
		display: block;
		position: absolute;
		left: 0;
		bottom: 30px;
		text-align: center;
		font-size: 1.4em;
		width: 100%;

		a {
			text-decoration: none;
			color: #000;
			opacity: .9;

			&:hover {
				opacity: 1;
			}
		}
		.arrow {
			font-size: .6em;
		}
	}
}
