$brand-color: #6ba1f3;

// Breakpoints
// $tablet: "(min-width: 450px)";
// $mid-point: "(min-width: 620px)";
// $desktop: "(min-width: 768px)";

$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";

$with-menu: "(max-width: 1087px)";